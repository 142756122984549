import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    MOBILE: "Mobile",
    WEB: "Web",
    DESKTOP: "Desktop",
  };

  const projectsData = [
    {
      title: "Mawjood",
      projectInfo: "<p>Monitor and calculate all your employees' permissions, vacations, attendance and departure with an existing system:</p><ul classname='list'><li>Accurate and automatic organization of vacations, salaries, rewards, penalties, advances and other features that guarantee you accuracy in completing administrative operations.</li><li>Controls attendance and departure times, and calculates delays, absences, and early departure.</li><li>Establishing accounting salaries linked to the progress of employee salaries.</li><li>Submit a vacation request from the application and specify its duration from hours to the number of days.</li><li>Through an existing program, you will be able to manage vacations, regardless of their number, without any errors.</li><li>It is suitable for various large, emerging or medium companies.</li><li>Enhance the performance of your employees and download an existing system.</li></ul>",
      client: "",
      technologies: "",
      industry: "",
      date: "",
      url: {
        name: "",
        link: "",
      },
      socialLinks: {
        facebook: "",
        twitter: "",
        google: "",
        instagram: "",
        mail: "",
      },
      thumbImage: "images/projects/mawjood/1.webp",
      sliderImages: [
        "images/projects/mawjood/2.webp",
        "images/projects/mawjood/3.webp",
        "images/projects/mawjood/4.webp",
        "images/projects/mawjood/5.webp",
      ],
      categories: [filters.WEB],
    },
    {
      title: "Super Point (سوبر نقطه)",
      projectInfo: "<p>It is an application for managing delivery requests for shipping companies. The system can be used with the integrated accounting system.</p> <p>Notifications, adding notes on orders, control by the driver and the customer, and an account statement for financial transactions with the ability to send news to customers or drivers, in addition to multiple features of the system.</p>",
      client: "",
      technologies: "",
      industry: "",
      date: "",
      url: {
        name: "",
        link: "",
      },
      socialLinks: {
        facebook: "",
        twitter: "",
        google: "",
        instagram: "",
        mail: "",
      },
      thumbImage: "images/projects/superpoint/1.webp",
      sliderImages: [
        "images/projects/superpoint/2.webp",
        "images/projects/superpoint/3.webp",
        "images/projects/superpoint/4.webp",
        "images/projects/superpoint/5.webp",
      ],
      categories: [filters.WEB],
    },
    {
      title: "Speedo Live (سبيدو لايف)",
      projectInfo: "<p>It is a shopping store for the strongest and finest international products for care and beauty products. Double your sales and make more profits by creating your online store:</p><ul className='list'><li>Comprehensive and easy to use control panel</li><li>Adding and coordinating products</li><li>Unlimited number of products</li><li>Manage the store and receive orders</li><li>Follow up on orders periodically</li><li>Complete statistics about your store</li><li>Link your store to shipping companies</li><li>Adding more than one branch within the system to ship each order from the branch closest to the customer</li><li>Quick access to customers without obstacles</li><li>Your customer will deliver to you from anywhere</li><li>Various and safe payment methods, cash on receipt</li><li>Our step-by-step support team is with you around the clock</li><li>Continuous free updates</li><li>Distinguish your online store with distinctive themes and colors</li></ul>",
      client: "",
      technologies: "",
      industry: "",
      date: "",
      url: {
        name: "",
        link: "",
      },
      socialLinks: {
        facebook: "",
        twitter: "",
        google: "",
        instagram: "",
        mail: "",
      },
      thumbImage: "images/projects/speedolive/1.png",
      sliderImages: [
        "images/projects/speedolive/2.jpg",
        "images/projects/speedolive/3.jpg",
        "images/projects/speedolive/4.jpg",
        "images/projects/speedolive/5.jpg",
        "images/projects/speedolive/6.jpg",
      ],
      categories: [filters.WEB],
    },
    {
      title: "Took Gaz (توك غاز)",
      projectInfo: "<p>1- The average user to request a gas cylinder 2 Gas stations, owners of gas stations and owners of logos - 1- Request easily 2 Evaluation of the quality of the request and the distributor Ease and convenience of copying service with you for the best specific cooking gas. The application is available in the first state of Iraq.</p>",
      client: "",
      technologies: "",
      industry: "",
      date: "",
      url: {
        name: "",
        link: "",
      },
      socialLinks: {
        facebook: "",
        twitter: "",
        google: "",
        instagram: "",
        mail: "",
      },
      thumbImage: "images/projects/tookgaz/1.webp",
      sliderImages: [
        "images/projects/tookgaz/2.webp",
        "images/projects/tookgaz/3.webp",
        "images/projects/tookgaz/4.webp",
        "images/projects/tookgaz/5.webp",
        "images/projects/tookgaz/6.webp",
        "images/projects/tookgaz/7.webp",
        "images/projects/tookgaz/8.webp",
      ],
      categories: [filters.WEB],
    },
    {
      title: "My Debits (ديوني)",
      projectInfo: "<p>Through this application, you can now control all the payments due through an easy and simple interface to manage all your accounts.</p> <p>You can also add several different accounts to determine the current accumulated debts, as well as the possibility of paying them, clearing the account balance correctly, and accurate account statement movement.</p>",
      client: "",
      technologies: "",
      industry: "",
      date: "",
      url: {
        name: "",
        link: "",
      },
      socialLinks: {
        facebook: "",
        twitter: "",
        google: "",
        instagram: "",
        mail: "",
      },
      thumbImage: "images/projects/mydebit/1.webp",
      sliderImages: [
        "images/projects/mydebit/2.webp",
        "images/projects/mydebit/3.webp",
        "images/projects/mydebit/4.webp",
        "images/projects/mydebit/5.webp",
        "images/projects/mydebit/6.webp",
      ],
      categories: [filters.WEB],
    },
    {
      title: "Speedo Captain (سبيدو كابتن)",
      projectInfo: "<p>The Speedo Captain app, synced with the Speedo Restaurant system, provides many of the features a captain needs in order to better accomplish daily tasks more efficiently and effectively.</p> <p>Speedo Captain offers you the ability to select user orders from a list of products previously added to the Speedo system, and then send the order directly to the kitchen. In addition, the application provides the ability to customize the order according to the customer's desire and to send detailed notes with the order.</p>",
      client: "",
      technologies: "",
      industry: "",
      date: "",
      url: {
        name: "",
        link: "",
      },
      socialLinks: {
        facebook: "",
        twitter: "",
        google: "",
        instagram: "",
        mail: "",
      },
      thumbImage: "images/projects/speedocaptain/1.png",
      sliderImages: [
        "images/projects/speedocaptain/2.jpg",
        "images/projects/speedocaptain/3.jpg",
        "images/projects/speedocaptain/4.jpg",
        "images/projects/speedocaptain/5.jpg",
      ],
      categories: [filters.WEB],
    },
    {
      title: "Protein (بروتين)",
      projectInfo: "<p>A shopping program based on special designs according to the needs of the customers</p>",
      client: "",
      technologies: "",
      industry: "",
      date: "",
      url: {
        name: "",
        link: "",
      },
      socialLinks: {
        facebook: "",
        twitter: "",
        google: "",
        instagram: "",
        mail: "",
      },
      thumbImage: "images/projects/protein/1.webp",
      sliderImages: [
        "images/projects/protein/2.webp",
        "images/projects/protein/3.webp",
        "images/projects/protein/4.webp",
        "images/projects/protein/5.webp",
      ],
      categories: [filters.WEB],
    },
    {
      title: "My Bookings (حجوزاتي)",
      projectInfo: "<p>A program for arranging reservations for clinics and companies, offices … linked to the annual calendar, and inquiries can control the available and unavailable days, specifying the maximum number of auditors with the ability to control the colors in the days calendar according to the number of customers</p>",
      client: "",
      technologies: "",
      industry: "",
      date: "",
      url: {
        name: "",
        link: "",
      },
      socialLinks: {
        facebook: "",
        twitter: "",
        google: "",
        instagram: "",
        mail: "",
      },
      thumbImage: "images/projects/mybooking/1.png",
      sliderImages: [
        "images/projects/mybooking/2.jpg",
        "images/projects/mybooking/3.jpg",
        "images/projects/mybooking/4.jpg",
        "images/projects/mybooking/5.jpg",
        "images/projects/mybooking/6.jpg",
      ],
      categories: [filters.WEB],
    },
    {
      title: "My Review (تقيمي)",
      projectInfo: "<p>An application program to evaluate any type of job</p>",
      client: "",
      technologies: "",
      industry: "",
      date: "",
      url: {
        name: "",
        link: "",
      },
      socialLinks: {
        facebook: "",
        twitter: "",
        google: "",
        instagram: "",
        mail: "",
      },
      thumbImage: "images/projects/myreview/1.png",
      sliderImages: [
        "images/projects/myreview/2.jpg",
        "images/projects/myreview/3.jpg",
        "images/projects/myreview/4.jpg",
        "images/projects/myreview/5.jpg",
        "images/projects/myreview/6.jpg",
      ],
      categories: [filters.WEB],
    },
    {
      title: "Car’s World (مدينه سيارات)",
      projectInfo: "<p>A program for buying and selling cars from all over the specified geographical area.</p>",
      client: "",
      technologies: "",
      industry: "",
      date: "",
      url: {
        name: "",
        link: "",
      },
      socialLinks: {
        facebook: "",
        twitter: "",
        google: "",
        instagram: "",
        mail: "",
      },
      thumbImage: "images/projects/car/1.png",
      sliderImages: [
        "images/projects/car/2.jpg",
        "images/projects/car/3.jpg",
        "images/projects/car/4.jpg",
        "images/projects/car/5.jpg",
      ],
      categories: [filters.WEB],
    },
    {
      title: "Bin Firnas (بن فرناس)",
      projectInfo: "<p>A travel and tourism specialization system that provides many services to customers. It supports two currencies, the dinar and the dollar. </p> <p>It is easy to use as it was developed with the latest technology. It supports the work of tourist groups in a very detailed manner, entering ticket invoices, tourist groups and other services in both dinars and dollars. </p> <p>The possibility of extracting invoices and creating a file for each customer. It supports multiple users within specific privileges. The monitoring screen to follow all user movements that cannot be deleted. Follow-up on profits, expenses and debits with high accuracy.</p> <p>The system contains a wide range of daily, monthly and annual reports and statements with all bills. The possibility of exporting reports to files (Excel, Word, PDF) to send them via the Internet. The possibility of recovering the invoice, whether it is a refund for a ticket, or the remainder of the amount that the customer paid, which reduces the possibility of a deficit or monthly increase.</p>",
      client: "",
      technologies: "",
      industry: "",
      date: "",
      url: {
        name: "",
        link: "",
      },
      socialLinks: {
        facebook: "",
        twitter: "",
        google: "",
        instagram: "",
        mail: "",
      },
      thumbImage: "images/projects/travel/1.png",
      sliderImages: [
        "images/projects/travel/2.jpg",
        "images/projects/travel/3.png",
        "images/projects/travel/4.png",
        "images/projects/travel/5.png",
      ],
      categories: [filters.WEB],
    },
    {
      title: "Beburj (بي برج)",
      projectInfo: "<p>A system for internet towers</p>",
      client: "",
      technologies: "",
      industry: "",
      date: "",
      url: {
        name: "",
        link: "",
      },
      socialLinks: {
        facebook: "",
        twitter: "",
        google: "",
        instagram: "",
        mail: "",
      },
      thumbImage: "images/projects/beburj/1.webp",
      sliderImages: [
        "images/projects/beburj/2.webp",
        "images/projects/beburj/3.webp",
        "images/projects/beburj/4.webp",
        "images/projects/beburj/5.webp",
      ],
      categories: [filters.WEB],
    },
    {
      title: "Viking Restaurant (مطعم فايكنك)",
      projectInfo: "<p>A special restaurant application that displays all the services available in the restaurant</p>",
      client: "",
      technologies: "",
      industry: "",
      date: "",
      url: {
        name: "",
        link: "",
      },
      socialLinks: {
        facebook: "",
        twitter: "",
        google: "",
        instagram: "",
        mail: "",
      },
      thumbImage: "images/projects/viking/1.png",
      sliderImages: [
        "images/projects/viking/2.jpg",
        "images/projects/viking/3.jpg",
        "images/projects/viking/4.jpg",
        "images/projects/viking/5.jpg",
        "images/projects/viking/6.jpg",
      ],
      categories: [filters.WEB],
    },
    {
      title: "My Request (طلبيه)",
      projectInfo: "<p>A sales program that facilitates identifying the  type of customer’s request for the type of item , determining the information and identity of the customer, as well as the ability to the arrival and receipt of the item.</p>",
      client: "",
      technologies: "",
      industry: "",
      date: "",
      url: {
        name: "",
        link: "",
      },
      socialLinks: {
        facebook: "",
        twitter: "",
        google: "",
        instagram: "",
        mail: "",
      },
      thumbImage: "images/projects/myrequest/1.png",
      sliderImages: [
        "images/projects/myrequest/2.jpg",
        "images/projects/myrequest/3.jpg",
        "images/projects/myrequest/4.jpg",
      ],
      categories: [filters.WEB],
    },
    {
      title: "AWS Bazar (اوس بازار)",
      projectInfo: "<p>Shopping program according to a special design from the customer</p>",
      client: "",
      technologies: "",
      industry: "",
      date: "",
      url: {
        name: "",
        link: "",
      },
      socialLinks: {
        facebook: "",
        twitter: "",
        google: "",
        instagram: "",
        mail: "",
      },
      thumbImage: "images/projects/aws/1.png",
      sliderImages: [
        "images/projects/aws/2.jpg",
        "images/projects/aws/3.jpg",
        "images/projects/aws/4.jpg",
        "images/projects/aws/5.jpg",
        "images/projects/aws/6.jpg",
        "images/projects/aws/7.jpg",
      ],
      categories: [filters.WEB],
    },
    {
      title: "Hader  (حاضر)",
      projectInfo: "<p>Fingerprint program to manage the attendance and departure of employees in the company</p>",
      client: "",
      technologies: "",
      industry: "",
      date: "",
      url: {
        name: "",
        link: "",
      },
      socialLinks: {
        facebook: "",
        twitter: "",
        google: "",
        instagram: "",
        mail: "",
      },
      thumbImage: "images/projects/attendance/1.png",
      sliderImages: [
        "images/projects/attendance/2.jpg",
        "images/projects/attendance/3.jpg",
        "images/projects/attendance/4.jpg",
        "images/projects/attendance/5.jpg",
        "images/projects/attendance/6.jpg",
        "images/projects/attendance/7.jpg",
      ],
      categories: [filters.WEB],
    },

  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              Portfolio
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          {/* <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul> */}
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-3 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image m-auto"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            {/* <span className="text-light">Category</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
